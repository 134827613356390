var render = function render(){
  var _vm$giftcard;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.template, {
    tag: "component",
    scopedSlots: _vm._u([{
      key: "fab",
      fn: function (_ref) {
        var showScroll = _ref.showScroll,
          scrollTo = _ref.scrollTo;
        return _vm.$vuetify.breakpoint.mobile && _vm.ready ? [_c('v-app-bar', {
          attrs: {
            "app": "",
            "bottom": "",
            "height": "auto",
            "color": "transparent",
            "elevation": "3"
          }
        }, [_c('v-responsive', {
          staticClass: "overflow-visible"
        }, [_c('v-card', [_c('v-expand-transition', [_c('v-responsive', {
          staticClass: "buy-fixed pa-4",
          class: {
            'buy-fixed--plusmoon': _vm.siteInfo.projectName == 'plusmoon'
          },
          attrs: {
            "elevation": "0"
          }
        }, [_c('div', {
          staticClass: "font-weight-medium"
        }, [_c('span', {
          staticClass: "grey--text mr-2"
        }, [_vm._v("01")]), _vm._v(" 권종 및 수량")]), _c('cart-list', {
          staticClass: "mt-5",
          model: {
            value: _vm.carts,
            callback: function ($$v) {
              _vm.carts = $$v;
            },
            expression: "carts"
          }
        }), _c('v-divider', {
          staticClass: "my-5"
        }), _c('div', {
          staticClass: "font-weight-medium"
        }, [_c('span', {
          staticClass: "grey--text mr-2"
        }, [_vm._v("02")]), _vm._v(" 결제수단")]), _c('v-btn-toggle', {
          staticClass: "mt-5 paymentMethods",
          attrs: {
            "mandatory": "",
            "color": "primary",
            "tile": ""
          },
          model: {
            value: _vm.order.paymentMethod,
            callback: function ($$v) {
              _vm.$set(_vm.order, "paymentMethod", $$v);
            },
            expression: "order.paymentMethod"
          }
        }, [_vm.paymentMethods.includes(`creditcard`) ? _c('v-btn', {
          attrs: {
            "outlined": "",
            "value": "creditcard"
          }
        }, [_c('div', [_c('div', {
          staticClass: "d-flex justify-space-between"
        }, [_c('div', {
          staticClass: "button-text font-size-14 font-size-md-16"
        }, [_vm._v("카드결제")]), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-check")])], 1)])]) : _vm._e(), _vm.paymentMethods.includes(`mobile`) ? _c('v-btn', {
          attrs: {
            "outlined": "",
            "value": "mobile"
          }
        }, [_c('div', [_c('div', {
          staticClass: "d-flex justify-space-between"
        }, [_c('div', {
          staticClass: "button-text font-size-14 font-size-md-16"
        }, [_vm._v("휴대폰결제")]), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-check")])], 1)])]) : _vm._e(), _vm.paymentMethods.includes(`simple`) ? _c('v-btn', {
          attrs: {
            "outlined": "",
            "value": "simple"
          }
        }, [_c('div', [_c('div', {
          staticClass: "d-flex justify-space-between"
        }, [_c('div', {
          staticClass: "button-text font-size-14 font-size-md-16"
        }, [_vm._v("간편결제")]), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-check")])], 1)])]) : _vm._e(), _vm.paymentMethods.includes(`point`) ? _c('v-btn', {
          attrs: {
            "outlined": "",
            "value": "point"
          }
        }, [_c('div', [_c('div', {
          staticClass: "d-flex justify-space-between"
        }, [_c('div', {
          staticClass: "button-text font-size-14 font-size-md-16"
        }, [_vm._v("포인트결제")]), _c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-check")])], 1)])]) : _vm._e()], 1), _c('v-sheet', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: 1 < _vm.paymentGateways.length,
            expression: "1 < paymentGateways.length"
          }],
          staticClass: "mt-2 pa-3",
          attrs: {
            "color": "#F7F7F7"
          }
        }, [_c('v-layout', {
          attrs: {
            "mandatory": "",
            "color": "primary"
          }
        }, [_c('v-radio-group', {
          attrs: {
            "hide-details": "",
            "row": ""
          },
          model: {
            value: _vm.order.paymentGateway,
            callback: function ($$v) {
              _vm.$set(_vm.order, "paymentGateway", $$v);
            },
            expression: "order.paymentGateway"
          }
        }, [_vm._l(_vm.paymentGateways, function (paymentGateway, index) {
          return [paymentGateway == `toss` ? _c('v-flex', {
            key: index
          }, [_c('v-radio', {
            attrs: {
              "label": "토스결제",
              "outlined": "",
              "value": paymentGateway
            }
          })], 1) : paymentGateway == `danal` ? _c('v-flex', {
            key: index
          }, [_c('v-radio', {
            attrs: {
              "label": "다날결제",
              "outlined": "",
              "value": paymentGateway
            }
          })], 1) : paymentGateway == `smartro` ? _c('v-flex', {
            key: index
          }, [_c('v-radio', {
            attrs: {
              "label": "페이레터결제",
              "outlined": "",
              "value": paymentGateway
            }
          })], 1) : _c('v-flex', {
            key: index
          }, [_c('v-radio', {
            attrs: {
              "label": paymentGateway,
              "outlined": "",
              "value": paymentGateway
            }
          })], 1)];
        })], 2)], 1), _vm.siteInfo.projectName == 'plusmoon' ? [_c('v-divider', {
          staticClass: "my-4"
        }), _c('div', {
          staticClass: "caption font-size-14"
        }, [_vm._v("* "), _c('strong', {
          staticClass: "font-size-14"
        }, [_vm._v("휴대폰 과금율 : 페이레터 - 4% , 토스 - 5%")])]), _c('div', {
          staticClass: "caption"
        }, [_vm._v("* 휴대폰 결제 수단"), _c('strong', {
          staticClass: "red--text font-size-14"
        }, [_vm._v("(페이레터 20, 토스20)")]), _vm._v(" 총 월 40만원까지 구매 가능 합니다.")]), _c('div', {
          staticClass: "caption"
        }, [_vm._v("* 카드는 월 100만원 "), _c('strong', {
          staticClass: "red--text"
        }, [_vm._v("일시불")]), _vm._v("로만 구매 가능합니다.")]), _c('div', {
          staticClass: "caption"
        }, [_vm._v("* "), _c('strong', {
          staticClass: "red--text"
        }, [_vm._v("피싱사기")]), _vm._v(" - 상품권 구매 유도하는 각종 사기가 많으니 주의 하시기 바랍니다.")]), _c('div', {
          staticClass: "caption"
        }, [_vm._v("* 모바일 상품권 특성상 문자로 핀번호가 노출 된 경우에는 "), _c('strong', {
          staticClass: "red--text"
        }, [_vm._v("환불이 불가합니다")]), _vm._v(".")])] : _vm._e()], 2), _vm.order.paymentMethod == `point` ? [_c('v-divider', {
          staticClass: "my-8"
        }), _c('v-layout', {
          staticClass: "mt-4 align-center"
        }, [_c('v-flex', {
          staticClass: "px-1"
        }, [_c('v-text-field', {
          attrs: {
            "label": "포인트 사용",
            "maxlength": "8",
            "prepend-inner-icon": "mdi-alpha-p-circle",
            "dense": "",
            "outlined": "",
            "hide-details": "",
            "persistent-placeholder": "",
            "readonly": !_vm.logon
          },
          model: {
            value: _vm.order.pointAmount,
            callback: function ($$v) {
              _vm.$set(_vm.order, "pointAmount", $$v);
            },
            expression: "order.pointAmount"
          }
        })], 1), _c('v-flex', {
          staticClass: "px-1 mb-2 mb-md-0",
          attrs: {
            "shrink": ""
          }
        }, [_c('v-btn', {
          attrs: {
            "color": "primary"
          },
          on: {
            "click": _vm.setPoint
          }
        }, [_vm._v("전액사용")])], 1)], 1), _vm.me ? _c('v-layout', {
          staticClass: "mt-1"
        }, [_c('v-flex', {
          staticClass: "px-1 mb-2 mb-md-0 caption",
          attrs: {
            "shrink": ""
          }
        }, [_vm._v("* "), _c('b', [_vm._v(_vm._s(_vm.me.name))]), _vm._v("님 보유 포인트 "), _c('span', {
          staticClass: "primary--text"
        }, [_c('b', [_vm._v(_vm._s(_vm.me.point.format()))])]), _c('v-icon', {
          attrs: {
            "color": "primary",
            "size": "20"
          }
        }, [_vm._v("mdi-alpha-p-circle")])], 1)], 1) : _vm._e()] : _vm._e(), _c('v-divider', {
          staticClass: "my-5"
        }), _c('div', {
          staticClass: "font-weight-medium mb-5"
        }, [_c('span', {
          staticClass: "grey--text mr-2"
        }, [_vm._v("03")]), _vm._v(" 금액 및 최종확인")]), _c('v-card', {
          staticClass: "rounded-0",
          attrs: {
            "color": "grey lighten-4",
            "elevation": "0"
          }
        }, [_vm._l(_vm.order.pickups, function (pickup, index) {
          return [0 < index ? _c('v-divider', {
            key: `d` + index,
            staticClass: "mx-4"
          }) : _vm._e(), _c('v-card-text', {
            key: index
          }, [_c('v-layout', [_c('span', [_c('div', [_vm._v(" " + _vm._s(pickup.name)), _c('span', {
            staticClass: "grey--text"
          }, [_vm._v(" X " + _vm._s(pickup.quantity))])])]), _c('v-spacer'), _c('span', [_vm._v(_vm._s((pickup.salePrice * pickup.quantity).format()) + "원")])], 1)], 1)];
        })], 2), _c('v-card', {
          staticClass: "mt-2 rounded-0",
          attrs: {
            "outlined": "",
            "elevation": "0"
          }
        }, [_c('v-card-text', [_c('v-layout', [_c('span', [_vm._v("결제수단")]), _c('v-spacer'), _vm.order.paymentMethod == `creditcard` ? _c('span', {
          staticClass: "grey--text text--darken-2"
        }, [_vm._v("카드결제")]) : _vm._e(), _vm.order.paymentMethod == `mobile` ? _c('span', {
          staticClass: "grey--text text--darken-2"
        }, [_vm._v("휴대폰결제")]) : _vm._e(), _vm.order.paymentMethod == `simple` ? _c('span', {
          staticClass: "grey--text text--darken-2"
        }, [_vm._v("간편결제")]) : _vm._e()], 1), _c('v-divider', {
          staticClass: "my-3"
        }), _c('v-layout', [_c('span', [_vm._v("결제 수수료")]), _c('v-spacer'), _c('span', {
          staticClass: "grey--text text--darken-2"
        }, [_vm._v(_vm._s(_vm.commissionAmount.format()) + "원")])], 1), _c('v-divider', {
          staticClass: "my-3"
        }), _c('v-layout', [_c('span', [_vm._v("상품 합계 금액")]), _c('v-spacer'), _c('span', {
          staticClass: "grey--text text--darken-2"
        }, [_vm._v(_vm._s(_vm.saleAmount.format()) + "원")])], 1)], 1)], 1), _c('v-layout', {
          staticClass: "mt-5"
        }, [_c('v-flex', {
          attrs: {
            "grow-shrink-0": ""
          }
        }, [_c('span', [_vm._v("최종 결제 금액")])]), _c('v-spacer'), _c('v-flex', {
          attrs: {
            "grow-shrink-0": ""
          }
        }, [_c('span', {
          staticClass: "primary--text d-flex align-center"
        }, [_c('b', {
          staticClass: "font-size-24 mr-1"
        }, [_vm._v(_vm._s(parseInt(_vm.totalAmount).format()))]), _vm._v(" 원 ")])])], 1), _c('v-divider', {
          staticClass: "my-5"
        }), _vm.tossBuyerEditable ? [_c('div', {
          staticClass: "font-weight-medium"
        }, [_c('span', {
          staticClass: "grey--text mr-2"
        }), _vm._v(" 결제자 휴대폰번호")]), _c('v-layout', {
          staticClass: "mt-5"
        }, [_c('v-flex', {
          staticStyle: {
            "flex-grow": "0"
          }
        }, [_c('v-select', {
          staticClass: "rounded-0",
          staticStyle: {
            "max-width": "120px"
          },
          attrs: {
            "items": _vm.phones,
            "readonly": !_vm.tossBuyerEditable,
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          model: {
            value: _vm.order.buyer.phone1,
            callback: function ($$v) {
              _vm.$set(_vm.order.buyer, "phone1", $$v);
            },
            expression: "order.buyer.phone1"
          }
        })], 1), _c('span', {
          staticClass: "mx-1"
        }), _c('v-flex', {
          staticClass: "grow-1"
        }, [_c('v-text-field', {
          staticClass: "rounded-0",
          attrs: {
            "label": "-없이 숫자만 입력",
            "readonly": !_vm.tossBuyerEditable,
            "outlined": "",
            "hide-details": "",
            "dense": "",
            "maxlength": "8"
          },
          on: {
            "input": function ($event) {
              var _vm$order$receiver$ph;
              _vm.order.receiver.phone2 = (_vm$order$receiver$ph = _vm.order.receiver.phone2) === null || _vm$order$receiver$ph === void 0 ? void 0 : _vm$order$receiver$ph.replace(/[^0-9]/g, '');
            }
          },
          model: {
            value: _vm.order.buyer.phone2,
            callback: function ($$v) {
              _vm.$set(_vm.order.buyer, "phone2", $$v);
            },
            expression: "order.buyer.phone2"
          }
        })], 1)], 1), _c('div', {
          staticClass: "mt-2"
        }, [_c('div', {
          staticClass: "caption"
        })])] : _vm._e(), _c('v-divider', {
          staticClass: "my-5"
        }), _c('div', {
          staticClass: "font-weight-medium"
        }, [_c('span', {
          staticClass: "grey--text mr-2"
        }), _vm._v(" 쿠폰받을 휴대폰번호")]), _c('v-layout', {
          staticClass: "mt-5"
        }, [_c('v-flex', {
          staticStyle: {
            "flex-grow": "0"
          }
        }, [_c('v-select', {
          staticClass: "rounded-0",
          staticStyle: {
            "max-width": "120px"
          },
          attrs: {
            "items": _vm.phones,
            "readonly": _vm.readonly,
            "outlined": "",
            "hide-details": "",
            "dense": ""
          },
          on: {
            "click": function ($event) {
              return _vm.onCertify();
            }
          },
          model: {
            value: _vm.order.receiver.phone1,
            callback: function ($$v) {
              _vm.$set(_vm.order.receiver, "phone1", $$v);
            },
            expression: "order.receiver.phone1"
          }
        })], 1), _c('span', {
          staticClass: "mx-1"
        }), _c('v-flex', {
          staticClass: "grow-1"
        }, [_c('v-text-field', {
          staticClass: "rounded-0",
          attrs: {
            "label": "-없이 숫자만 입력",
            "readonly": _vm.readonly,
            "outlined": "",
            "hide-details": "",
            "dense": "",
            "maxlength": "8"
          },
          on: {
            "click": function ($event) {
              return _vm.onCertify();
            },
            "input": function ($event) {
              var _vm$order$receiver$ph2;
              _vm.order.receiver.phone2 = (_vm$order$receiver$ph2 = _vm.order.receiver.phone2) === null || _vm$order$receiver$ph2 === void 0 ? void 0 : _vm$order$receiver$ph2.replace(/[^0-9]/g, '');
            }
          },
          model: {
            value: _vm.order.receiver.phone2,
            callback: function ($$v) {
              _vm.$set(_vm.order.receiver, "phone2", $$v);
            },
            expression: "order.receiver.phone2"
          }
        })], 1)], 1), _c('div', {
          staticClass: "mt-2"
        }, [_c('div', {
          staticClass: "caption"
        }, [_vm._v("* 입력한 번호로 쿠폰 묶음이 전송됩니다. 정확하게 입력해주세요.")]), _c('div', {
          staticClass: "caption"
        }, [_vm._v("* 휴대폰 결제는 입력한 번호로만 결제할 수 있습니다.")]), _vm.readonly ? _c('div', {
          staticClass: "caption"
        }, [_vm._v("* 가입한 휴대폰번호로만 쿠폰이 발송됩니다.")]) : _vm._e()])], 2)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-layout', {
          staticClass: "py-1"
        }, [_c('v-flex', {
          staticClass: "px-1"
        }, [_c('v-btn', {
          attrs: {
            "block": "",
            "color": "primary",
            "x-large": ""
          },
          on: {
            "click": function ($event) {
              return _vm.purchase();
            }
          }
        }, [_c('span', {
          staticClass: "subtitle-2 white--text"
        }, [_vm._v("구매하기")])])], 1)], 1)], 1)], 1)], 1)], 1)] : undefined;
      }
    }], null, true)
  }, [_vm.ready ? [_vm.ready && _vm.giftcard ? _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onIntersect,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onIntersect, options: { threshold: [0, 1.0] } }"
    }],
    staticClass: "pb-4",
    attrs: {
      "width": "100%"
    }
  }, [_c('v-card', {
    attrs: {
      "tile": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', {
    staticClass: "body-1"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pa-3 pa-md-6",
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": "",
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "max-width": "480",
      "aspect-ratio": 1 / 1
    }
  }, [_c('v-carousel', {
    attrs: {
      "height": "auto",
      "hide-delimiters": "",
      "show-arrows-on-hover": "",
      "show-arrows": 1 < _vm.giftcard.images.length
    }
  }, _vm._l(_vm.giftcard.images, function (image, index) {
    return _c('v-carousel-item', {
      key: index
    }, [_c('v-img', {
      attrs: {
        "src": (image === null || image === void 0 ? void 0 : image.path) || (image === null || image === void 0 ? void 0 : image.src),
        "width": "100%",
        "contain": "",
        "aspect-ratio": 1
      }
    })], 1);
  }), 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "pa-3 pa-md-6",
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%"
    }
  }, [[_c('div', {
    staticClass: "headline primary--text"
  }, [_c('p', {
    domProps: {
      "innerHTML": _vm._s(_vm.giftcard.name)
    }
  })])], _c('v-divider', {
    staticClass: "my-5"
  }), !_vm.$vuetify.breakpoint.mobile && _vm.carts.length ? [_c('div', {
    staticClass: "font-weight-medium"
  }, [_c('span', {
    staticClass: "grey--text mr-2"
  }, [_vm._v("01")]), _vm._v(" 권종 및 수량")]), _c('cart-list', {
    staticClass: "mt-5",
    model: {
      value: _vm.carts,
      callback: function ($$v) {
        _vm.carts = $$v;
      },
      expression: "carts"
    }
  }), _c('v-divider', {
    staticClass: "my-5"
  }), _c('div', {
    staticClass: "font-weight-medium"
  }, [_c('span', {
    staticClass: "grey--text mr-2"
  }, [_vm._v("02")]), _vm._v(" 결제수단")]), _c('v-btn-toggle', {
    staticClass: "mt-5 paymentMethods",
    attrs: {
      "mandatory": "",
      "color": "primary",
      "tile": ""
    },
    model: {
      value: _vm.order.paymentMethod,
      callback: function ($$v) {
        _vm.$set(_vm.order, "paymentMethod", $$v);
      },
      expression: "order.paymentMethod"
    }
  }, [_vm.paymentMethods.includes(`creditcard`) ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "value": "creditcard"
    }
  }, [_c('div', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "button-text font-size-14 font-size-md-16"
  }, [_vm._v("카드결제")]), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")])], 1)])]) : _vm._e(), _vm.paymentMethods.includes(`mobile`) ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "value": "mobile"
    }
  }, [_c('div', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "button-text font-size-14 font-size-md-16"
  }, [_vm._v("휴대폰결제")]), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")])], 1)])]) : _vm._e(), _vm.paymentMethods.includes(`simple`) ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "value": "simple"
    }
  }, [_c('div', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "button-text font-size-14 font-size-md-16"
  }, [_vm._v("간편결제")]), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")])], 1)])]) : _vm._e(), _vm.paymentMethods.includes(`point`) ? _c('v-btn', {
    attrs: {
      "outlined": "",
      "value": "point"
    }
  }, [_c('div', [_c('div', {
    staticClass: "d-flex justify-space-between"
  }, [_c('div', {
    staticClass: "button-text font-size-14 font-size-md-16"
  }, [_vm._v("포인트결제")]), _c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")])], 1)])]) : _vm._e()], 1), _c('v-sheet', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: 1 < _vm.paymentGateways.length,
      expression: "1 < paymentGateways.length"
    }],
    staticClass: "mt-2 pa-3",
    attrs: {
      "color": "#F7F7F7"
    }
  }, [_c('v-layout', {
    attrs: {
      "mandatory": "",
      "color": "primary"
    }
  }, [_c('v-radio-group', {
    staticClass: "mt-0 pt-0",
    attrs: {
      "hide-details": "",
      "row": ""
    },
    model: {
      value: _vm.order.paymentGateway,
      callback: function ($$v) {
        _vm.$set(_vm.order, "paymentGateway", $$v);
      },
      expression: "order.paymentGateway"
    }
  }, [_vm._l(_vm.paymentGateways, function (paymentGateway, index) {
    return [paymentGateway == `toss` ? _c('v-flex', {
      key: index
    }, [_c('v-radio', {
      attrs: {
        "hide-details": "",
        "label": "토스결제",
        "value": paymentGateway
      }
    })], 1) : paymentGateway == `danal` ? _c('v-flex', {
      key: index
    }, [_c('v-radio', {
      attrs: {
        "hide-details": "",
        "label": "다날결제",
        "value": paymentGateway
      }
    })], 1) : paymentGateway == `smartro` ? _c('v-flex', {
      key: index
    }, [_c('v-radio', {
      attrs: {
        "hide-details": "",
        "label": "페이레터결제",
        "value": paymentGateway
      }
    })], 1) : _c('v-flex', {
      key: index
    }, [_c('v-radio', {
      attrs: {
        "label": paymentGateway,
        "value": paymentGateway
      }
    })], 1)];
  })], 2)], 1), _vm.siteInfo.projectName == 'plusmoon' ? [_c('v-divider', {
    staticClass: "my-4"
  }), _c('div', {
    staticClass: "caption"
  }, [_vm._v("* "), _c('strong', {
    staticClass: "font-size-14"
  }, [_vm._v("휴대폰 과금율 : 페이레터 - 4% , 토스 - 5%")])]), _c('div', {
    staticClass: "caption"
  }, [_vm._v("* 휴대폰 결제 수단"), _c('strong', {
    staticClass: "red--text font-size-14"
  }, [_vm._v("(페이레터 20, 토스20)")]), _vm._v(" 총 월 40만원까지 구매 가능 합니다.")]), _c('div', {
    staticClass: "caption"
  }, [_vm._v("* 카드는 월 100만원 "), _c('strong', {
    staticClass: "red--text"
  }, [_vm._v("일시불")]), _vm._v("로만 구매 가능합니다.")]), _c('div', {
    staticClass: "caption"
  }, [_vm._v("* "), _c('strong', {
    staticClass: "red--text"
  }, [_vm._v("피싱사기")]), _vm._v(" - 상품권 구매 유도하는 각종 사기가 많으니 주의 하시기 바랍니다.")]), _c('div', {
    staticClass: "caption"
  }, [_vm._v("* 모바일 상품권 특성상 문자로 핀번호가 노출 된 경우에는 "), _c('strong', {
    staticClass: "red--text"
  }, [_vm._v("환불이 불가합니다.")])])] : _vm._e()], 2), _vm.order.paymentMethod == `point` ? [_c('v-divider', {
    staticClass: "my-8"
  }), _c('v-layout', {
    staticClass: "mt-4 align-center"
  }, [_c('v-flex', {
    staticClass: "px-1"
  }, [_c('v-text-field', {
    attrs: {
      "label": "포인트 사용",
      "maxlength": "8",
      "prepend-inner-icon": "mdi-alpha-p-circle",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "persistent-placeholder": "",
      "readonly": !_vm.logon
    },
    model: {
      value: _vm.order.pointAmount,
      callback: function ($$v) {
        _vm.$set(_vm.order, "pointAmount", $$v);
      },
      expression: "order.pointAmount"
    }
  })], 1), _c('v-flex', {
    staticClass: "px-1 mb-2 mb-md-0",
    attrs: {
      "shrink": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "color": "primary"
    },
    on: {
      "click": _vm.setPoint
    }
  }, [_vm._v("전액사용")])], 1)], 1), _vm.me ? _c('v-layout', {
    staticClass: "mt-1"
  }, [_c('v-flex', {
    staticClass: "px-1 mb-2 mb-md-0 caption",
    attrs: {
      "shrink": ""
    }
  }, [_vm._v("* "), _c('b', [_vm._v(_vm._s(_vm.me.name))]), _vm._v("님 보유 포인트 "), _c('span', {
    staticClass: "primary--text"
  }, [_c('b', [_vm._v(_vm._s(_vm.me.point.format()))])]), _c('v-icon', {
    attrs: {
      "color": "primary",
      "size": "20"
    }
  }, [_vm._v("mdi-alpha-p-circle")])], 1)], 1) : _vm._e()] : _vm._e(), _c('v-divider', {
    staticClass: "my-5"
  }), _c('div', {
    staticClass: "font-weight-medium mb-5"
  }, [_c('span', {
    staticClass: "grey--text mr-2"
  }, [_vm._v("03")]), _vm._v(" 금액 및 최종확인")]), _c('v-card', {
    staticClass: "rounded-0",
    attrs: {
      "color": "grey lighten-4",
      "elevation": "0"
    }
  }, [_vm._l(_vm.order.pickups, function (pickup, index) {
    return [0 < index ? _c('v-divider', {
      key: `d` + index,
      staticClass: "mx-5"
    }) : _vm._e(), _c('v-card-text', {
      key: index
    }, [_c('v-layout', [_c('span', [_c('div', [_vm._v(" " + _vm._s(pickup.name)), _c('span', {
      staticClass: "grey--text"
    }, [_vm._v(" X " + _vm._s(pickup.quantity))])])]), _c('v-spacer'), _c('span', [_vm._v(_vm._s((pickup.salePrice * pickup.quantity).format()) + "원")])], 1)], 1)];
  })], 2), _c('v-card', {
    staticClass: "mt-2 rounded-0",
    attrs: {
      "outlined": "",
      "elevation": "0"
    }
  }, [_c('v-card-text', [_c('v-layout', [_c('span', [_vm._v("결제수단")]), _c('v-spacer'), _vm.order.paymentMethod == `creditcard` ? _c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("카드결제")]) : _vm._e(), _vm.order.paymentMethod == `mobile` ? _c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("휴대폰결제")]) : _vm._e(), _vm.order.paymentMethod == `simple` ? _c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v("간편결제")]) : _vm._e()], 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-layout', [_c('span', [_vm._v("결제 수수료")]), _c('v-spacer'), _c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v(_vm._s(_vm.commissionAmount.format()) + "원")])], 1), _c('v-divider', {
    staticClass: "my-3"
  }), _c('v-layout', [_c('span', [_vm._v("상품 합계 금액")]), _c('v-spacer'), _c('span', {
    staticClass: "grey--text text--darken-2"
  }, [_vm._v(_vm._s(_vm.saleAmount.format()) + "원")])], 1)], 1)], 1), _c('v-layout', {
    staticClass: "mt-5"
  }, [_c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_c('span', [_vm._v("최종 결제 금액")])]), _c('v-spacer'), _c('v-flex', {
    attrs: {
      "grow-shrink-0": ""
    }
  }, [_c('span', {
    staticClass: "primary--text d-flex align-center"
  }, [_c('b', {
    staticClass: "font-size-24 mr-1"
  }, [_vm._v(_vm._s(parseInt(_vm.totalAmount).format()))]), _vm._v(" 원 ")])])], 1), _c('v-divider', {
    staticClass: "my-5"
  }), _vm.tossBuyerEditable ? [_c('div', {
    staticClass: "font-weight-medium"
  }, [_c('span', {
    staticClass: "grey--text mr-2"
  }), _vm._v(" 결제자 휴대폰번호")]), _c('v-layout', {
    staticClass: "mt-5"
  }, [_c('v-flex', {
    staticStyle: {
      "flex-grow": "0"
    }
  }, [_c('v-select', {
    staticClass: "rounded-0",
    staticStyle: {
      "max-width": "120px"
    },
    attrs: {
      "items": _vm.phones,
      "disabled": !_vm.tossBuyerEditable,
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    model: {
      value: _vm.order.buyer.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.order.buyer, "phone1", $$v);
      },
      expression: "order.buyer.phone1"
    }
  })], 1), _c('span', {
    staticClass: "mx-1"
  }), _c('v-flex', {
    staticClass: "grow-1"
  }, [_c('v-text-field', {
    staticClass: "rounded-0",
    attrs: {
      "label": "-없이 숫자만 입력",
      "disabled": !_vm.tossBuyerEditable,
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "maxlength": "8"
    },
    on: {
      "input": function ($event) {
        var _vm$order$receiver$ph3;
        _vm.order.receiver.phone2 = (_vm$order$receiver$ph3 = _vm.order.receiver.phone2) === null || _vm$order$receiver$ph3 === void 0 ? void 0 : _vm$order$receiver$ph3.replace(/[^0-9]/g, '');
      }
    },
    model: {
      value: _vm.order.buyer.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.order.buyer, "phone2", $$v);
      },
      expression: "order.buyer.phone2"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticClass: "caption"
  })])] : _vm._e(), _c('v-divider', {
    staticClass: "my-5"
  }), _c('div', {
    staticClass: "font-weight-medium"
  }, [_c('span', {
    staticClass: "grey--text mr-2"
  }), _vm._v(" 쿠폰받을 휴대폰번호")]), _c('v-layout', {
    staticClass: "mt-5"
  }, [_c('v-flex', {
    staticStyle: {
      "flex-grow": "0"
    }
  }, [_c('v-select', {
    staticClass: "rounded-0",
    staticStyle: {
      "max-width": "120px"
    },
    attrs: {
      "items": _vm.phones,
      "readonly": _vm.readonly,
      "outlined": "",
      "hide-details": "",
      "dense": ""
    },
    on: {
      "click": function ($event) {
        return _vm.onCertify();
      }
    },
    model: {
      value: _vm.order.receiver.phone1,
      callback: function ($$v) {
        _vm.$set(_vm.order.receiver, "phone1", $$v);
      },
      expression: "order.receiver.phone1"
    }
  })], 1), _c('span', {
    staticClass: "mx-1"
  }), _c('v-flex', {
    staticClass: "grow-1"
  }, [_c('v-text-field', {
    staticClass: "rounded-0",
    attrs: {
      "label": "-없이 숫자만 입력",
      "readonly": _vm.readonly,
      "outlined": "",
      "hide-details": "",
      "dense": "",
      "maxlength": "8"
    },
    on: {
      "click": function ($event) {
        return _vm.onCertify();
      },
      "input": function ($event) {
        var _vm$order$receiver$ph4;
        _vm.order.receiver.phone2 = (_vm$order$receiver$ph4 = _vm.order.receiver.phone2) === null || _vm$order$receiver$ph4 === void 0 ? void 0 : _vm$order$receiver$ph4.replace(/[^0-9]/g, '');
      }
    },
    model: {
      value: _vm.order.receiver.phone2,
      callback: function ($$v) {
        _vm.$set(_vm.order.receiver, "phone2", $$v);
      },
      expression: "order.receiver.phone2"
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticClass: "caption"
  }, [_vm._v("* 입력한 번호로 쿠폰 묶음이 전송됩니다. 정확하게 입력해주세요.")]), _c('div', {
    staticClass: "caption"
  }, [_vm._v("* 휴대폰 결제는 입력한 번호로만 결제할 수 있습니다.")]), _vm.readonly ? _c('div', {
    staticClass: "caption"
  }, [_vm._v("* 가입한 휴대폰번호로만 쿠폰이 발송됩니다.")]) : _vm._e()]), _c('v-layout', {
    staticClass: "mt-5"
  }, [_c('v-flex', {
    attrs: {
      "grow": ""
    }
  }, [_c('v-btn', {
    attrs: {
      "tile": "",
      "block": "",
      "dark": "",
      "x-large": "",
      "color": "primary",
      "elevation": "0"
    },
    on: {
      "click": function ($event) {
        return _vm.purchase();
      }
    }
  }, [_vm._v("구매하기")])], 1)], 1)] : _vm._e()], 2)], 1)], 1)], 1)], 1)], 1) : _vm._e(), !_vm.isIntersecting ? _c('v-app-bar', {
    attrs: {
      "height": "50",
      "fixed": "",
      "color": "white",
      "elevation": "1"
    }
  }, [_c('v-layout', {
    attrs: {
      "wrap": "",
      "justify-center": ""
    }
  }, [_c('v-responsive', {
    attrs: {
      "width": "100%",
      "max-width": "1200"
    }
  }, [_c('v-tabs', {
    staticClass: "pa-0",
    attrs: {
      "show-arrows": "",
      "grow": ""
    },
    model: {
      value: _vm.intersectingIndex,
      callback: function ($$v) {
        _vm.intersectingIndex = $$v;
      },
      expression: "intersectingIndex"
    }
  }, [_c('v-tab', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "tile": "",
      "ripple": false,
      "height": "100%",
      "href": "#giftcard-content"
    }
  }, [_vm._v("상품정보")])], 1), _c('v-tab', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "tile": "",
      "ripple": false,
      "height": "100%",
      "href": "#giftcard-information"
    }
  }, [_vm._v("구매정보")])], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('v-responsive', [_c('v-card', [_c('v-tabs', {
    staticClass: "pa-0",
    attrs: {
      "show-arrows": "",
      "grow": ""
    },
    model: {
      value: _vm.intersectingIndex,
      callback: function ($$v) {
        _vm.intersectingIndex = $$v;
      },
      expression: "intersectingIndex"
    }
  }, [_c('v-tab', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "tile": "",
      "ripple": false,
      "height": "100%",
      "href": "#giftcard-content"
    }
  }, [_vm._v("상품정보")])], 1), _c('v-tab', {
    staticClass: "pa-0"
  }, [_c('v-btn', {
    attrs: {
      "text": "",
      "block": "",
      "tile": "",
      "ripple": false,
      "height": "100%",
      "href": "#giftcard-information"
    }
  }, [_vm._v("구매정보")])], 1)], 1), _c('v-divider'), _c('div', {
    staticClass: "mt-lg-4 pt-12",
    attrs: {
      "id": "giftcard-content"
    }
  }), _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onDetails,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onDetails, options: { threshold: [0, 1.0] } }"
    }]
  }, [_c('v-card-text', {
    staticClass: "mt-4"
  }, [_c('v-img', {
    attrs: {
      "src": "/images/giftcards/gift-guide.jpg"
    }
  }), _c('p', {
    staticClass: "pa-lg-4 img-products-views",
    domProps: {
      "innerHTML": _vm._s((_vm$giftcard = _vm.giftcard) === null || _vm$giftcard === void 0 ? void 0 : _vm$giftcard.content)
    }
  })], 1)], 1), _c('div', {
    staticClass: "mt-lg-4 pt-lg-12",
    attrs: {
      "id": "giftcard-information"
    }
  }), _c('v-responsive', {
    directives: [{
      name: "intersect",
      rawName: "v-intersect",
      value: {
        handler: _vm.onInformation,
        options: {
          threshold: [0, 1.0]
        }
      },
      expression: "{ handler: onInformation, options: { threshold: [0, 1.0] } }"
    }],
    staticClass: "pa-3"
  }, [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "auto"
    }
  }, [_c('caption', {
    staticClass: "caption_hidden",
    staticStyle: {
      "display": "inline-block",
      "margin": "10px",
      "padding": "0px",
      "box-sizing": "inherit",
      "font-size": "18px"
    }
  }, [_vm._v(" 교환/반품/품절안내 ")])])], 1), _vm._l(_vm.setting.informations, function (info, index) {
    return [_c('v-row', {
      key: index
    }, [_c('v-col', {
      staticClass: "grey lighten-3 d-flex align-center justify-center",
      attrs: {
        "cols": "12",
        "md": "4"
      }
    }, [_c('p', {
      staticClass: "text-center",
      domProps: {
        "innerHTML": _vm._s(info.term)
      }
    })]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "8"
      }
    }, [_c('p', {
      domProps: {
        "innerHTML": _vm._s(info.desc)
      }
    })])], 1)];
  })], 2)], 1), _c('login-dialog', {
    ref: "loginDialog"
  })], 1)] : _vm._e(), _c('kcp-cert', {
    ref: "kcpCert",
    on: {
      "input": _vm.onVerify
    }
  }), _c('payment-gateway', {
    ref: "paymentGateway",
    on: {
      "complete": _vm.complete
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }